<template>
    <div class="scroll-wrapper"
         :class="[ directionBlockSlider === 'vertical' ?
        'scroll-wrapper_vertical': 'scroll-wrapper_horizontal'
        ]">

        <div class="scroll-wrapper__content"
             :class="[ directionBlockSlider === 'vertical' ?
             'scroll-wrapper_content_vertical': 'scroll-wrapper_content_horizontal'
             ]">

            <div id="mainScrollPage">

                <div class="scroll-wrapper__content-box"
                     :class="[ directionBlockSlider === 'vertical' ?
                     'scroll-wrapper_content-box_vertical ':
                     'scroll-wrapper_content-box_horizontal'
                    ]">

                    <div class="scroll-wrapper__logo">
                    <img :src="require(`@/assets/images/main/logo-backer.svg`)" alt="logo"/>
                    </div>

                    <div class="scroll-wrapper__content-component">
                        <main-swiper :slides="slidesMain" :key="componentKey"></main-swiper>
                        <div class="language" @click="switchLanguage">
                            {{$i18n.locale}}
                        </div>
                    </div>
                </div>

                <div class="scroll-wrapper__content-box"
                     :class="[ directionBlockSlider === 'vertical' ?
                     'scroll-wrapper_content-box_vertical ':
                     'scroll-wrapper_content-box_horizontal'
                     ]">
                    <div class="scroll-wrapper__content-component">
                        <right-place :items="slidesPlace"></right-place>
                    </div>
                </div>

                <div class="scroll-wrapper__content-box scroll-wrapper_content-box_3-slides"
                     :class="[ directionBlockSlider === 'vertical' ?
                     'scroll-wrapper_content-box_vertical ':
                     'scroll-wrapper_content-box_horizontal'
                     ]">
                    <div class="scroll-wrapper__content-component">
                        <achievements :items="achievementsItems"></achievements>
                    </div>
                </div>

                <div class="scroll-wrapper__content-box"
                     :class="[ directionBlockSlider === 'vertical' ?
                     'scroll-wrapper_content-box_vertical ':
                     'scroll-wrapper_content-box_horizontal'
                     ]">
                    <div class="scroll-wrapper__content-component">
                        <contacts></contacts>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { scrollPageX, scrollPageY } from '../core/helpers/scroll-page';
import {
  SLIDES_MAIN,
  SLIDES_PLACE,
  ACHIEVEMENTS_ITEMS,
  LEFT_ASIDE_PANEL,
} from '../core/utils/constants';
import mainSwiper from './components/main-swiper.vue';
import rightPlace from './components/right-place.vue';
import achievements from './components/achievements.vue';
import contacts from './components/contacts/contacts.vue';

export default {
  name: 'Home',
  components: {
    mainSwiper,
    rightPlace,
    achievements,
    contacts,
  },

  watch: {
    // при изменениях маршрута запрашиваем данные снова
    $route: 'toSlide',
  },

  methods: {
    initBlockSlider(currentWidth, slidesCount) {
      if (currentWidth > 767) {
        this.directionBlockSlider = 'horizontal';
        scrollPageX(this.blockId, slidesCount, 1300, this.scrollEvents);
      } else {
        this.directionBlockSlider = 'vertical';

        // eslint-disable-next-line no-restricted-globals
        if (screen.availWidth <= 767) {
          scrollPageY(this.blockId, slidesCount, '', this.scrollEvents);
        } else {
          scrollPageY(this.blockId, slidesCount, '1200', this.scrollEvents);
        }
      }
    },

    switchLanguage() {
      if (this.$i18n.locale === 'ru') {
        this.$i18n.locale = 'en';
      } else {
        this.$i18n.locale = 'ru';
      }
      window.localStorage.setItem('Language', this.$i18n.locale);
      this.forceRerender();
    },

    forceRerender() {
      this.componentKey = this.$i18n.locale;
    },

    updateSlidesCount(currentWidth) {
      if (currentWidth > 767) {
        this.slidesCount = document.querySelectorAll('.scroll-wrapper__content-component').length;
      } else {
        this.slidesCount = 6;
      }
    },

    toSlide() {
      const { slide } = this.$route.query;
      if (slide) {
        document.querySelector('#mainScrollPage');
        const slider = document.querySelector('#mainScrollPage');
        if (slider) {
          slider.style.transform = `translateX(-${slide * 100}vw)`;
        }

        if (slider) {
          const currentWidth = window.innerWidth;

          if (currentWidth <= 767) {
            const currentSlide = this.findMobileSlide(slide);
            slider.style.transform = `translateY(-${currentSlide.mobileSlide * 100}vh)`;
          } else {
            slider.style.transform = `translateX(-${slide * 100}vw)`;
          }
        }
      }
    },

    findMobileSlide(slideNumber) {
      return this.menuItems.find((menuItem) => menuItem.slide === +slideNumber);
    },
  },

  mounted() {
    this.currentWidth = window.innerWidth;

    this.updateSlidesCount(this.currentWidth);

    this.initBlockSlider(this.currentWidth, this.slidesCount);

    window.addEventListener('resize', () => {
      const newWidth = window.innerWidth;
      if (((this.currentWidth > 767) && (newWidth <= 767))
          || ((this.currentWidth <= 767) && (newWidth > 767))) {
        this.currentWidth = newWidth;
        this.initBlockSlider(this.currentWidth, this.slidesCount);
      }
    });

    this.toSlide();
  },

  data() {
    return {
      langs: ['en', 'ru'],
      componentKey: this.$i18n.locale,

      scrollEvents: [],
      blockId: 'mainScrollPage',
      directionBlockSlider: '',
      currentWidth: '',
      slidesCount: '',

      slidesMain: SLIDES_MAIN,
      slidesPlace: SLIDES_PLACE,
      achievementsItems: ACHIEVEMENTS_ITEMS,

      menuItems: LEFT_ASIDE_PANEL.itemsMenu,
    };
  },
};
</script>
