<template>

    <div class="easy-find-container">
        <div class="easy-find-block">
            <google-map :name="name" :locations="locations"></google-map>

            <div class="easy-find-block__text">
                <div class="easy-find-block__text-title">
                    {{ $tm('text.contactsTitle') }}
                </div>

            </div>

            <find-form></find-form>

        </div>

        <aside-menu class="right-side-menu" :options="optionsAsidePanel"></aside-menu>

    </div>

</template>

<script>
import { Aside, GoogleMap } from '@/shared-components';
import findForm from './components/find-form.vue';
import { RIGHT_ASIDE_PANEL } from '../../../core/utils/constants';

export default {
  name: 'contacts',
  components: {
    'aside-menu': Aside,
    'google-map': GoogleMap,
    'find-form': findForm,
  },

  props: {
    items: Array,
  },

  data() {
    return {
      name: 'map',

      locations: {
        locations: [
          {
            id: 1, lat: 47.262008, lng: 39.717362, name_point: '', title: 'Backer Stream',
          },
        ],
      },

      optionsAsidePanel: RIGHT_ASIDE_PANEL,
    };
  },
};
</script>
