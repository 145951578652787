import ScrollEvent from '../models/scrollEvent';

function removeEvents(scrollBlock, scrollEvents) {
  if (scrollEvents.length) {
    scrollEvents.forEach((item) => {
      scrollBlock.removeEventListener(item.eventType, item.eventHandler);
    });
  }
}

function getScrollDirection(e, currentScrollDirection) {
  let scrollDirection = currentScrollDirection;
  if (e.deltaY < 0) {
    scrollDirection = 'down';
  }
  if (e.deltaY > 0) {
    scrollDirection = 'up';
  }

  return scrollDirection;
}

export function scrollPageX(blockId, slidesCount, delay, scrollEvents) {
  let scrollDirection;
  let hold = false;

  function scrollX(obj) {
    const step = 100;
    let offsetPage;
    let scrollBlock;

    if (obj.id === blockId) {
      scrollBlock = obj;
    } else if (obj.currentTarget.id === blockId) {
      scrollBlock = obj.currentTarget;
    }

    if (scrollBlock === undefined) {
      return;
    }

    scrollDirection = getScrollDirection(obj, scrollDirection);

    offsetPage = parseInt(scrollBlock.style.transform.replace('translateX(', ''), 10);

    if (scrollDirection === 'up' && Math.abs(offsetPage / 100) < (slidesCount - 1)) {
      offsetPage -= step;
    } else if (scrollDirection === 'down' && offsetPage < 0) {
      offsetPage += step;
    }

    if (hold === false) {
      hold = true;
      scrollBlock.classList.add('animateScroll');
      scrollBlock.style.transform = `translateX(${offsetPage}vw)`;
      setTimeout(() => {
        hold = false;
        scrollBlock.classList.remove('animateScroll');
      }, delay);
    }
  }

  const mainScrollPage = document.querySelector(`#${blockId}`);

  removeEvents(mainScrollPage, scrollEvents);

  mainScrollPage.style.transform = 'translateX(0)';

  mainScrollPage.addEventListener('wheel', scrollX);

  scrollEvents.push(new ScrollEvent('wheel', scrollX));
}

export function scrollPageY(blockId, slidesCount, delay, scrollEvents) {
  let scrollDirection;
  // eslint-disable-next-line no-unused-vars
  let hold = false;

  function scrollY(obj) {
    const step = 100;
    let offsetPage;
    let scrollBlock = '';

    if (obj.id === blockId) {
      scrollBlock = obj;
    } else if (obj.currentTarget.id === blockId) {
      scrollBlock = obj.currentTarget;
    }

    if (scrollBlock === undefined) {
      return;
    }

    scrollDirection = getScrollDirection(obj, scrollDirection);

    offsetPage = parseInt(scrollBlock.style.transform.replace('translateY(', ''), 10);

    if (scrollDirection === 'up' && Math.abs(offsetPage / 100) < (slidesCount - 1)) {
      offsetPage -= step;
    } else if (scrollDirection === 'down' && offsetPage < 0) {
      offsetPage += step;
    }

    if (delay) {
      if (hold === false) {
        hold = true;
        scrollBlock.classList.add('animateScroll');
        scrollBlock.style.transform = `translateY(${offsetPage}vh)`;
        setTimeout(() => {
          scrollBlock.classList.remove('animateScroll');
          hold = false;
        }, delay);
      }
    } else {
      scrollBlock.classList.add('animateScroll');
      scrollBlock.style.transform = `translateY(${offsetPage}vh)`;
      // setTimeout(() => {
      //   scrollBlock.classList.remove('animateScroll');
      //   hold = false;
      // }, 1000);
    }
  }

  function swipe(obj) {
    let swdir;
    let sX;
    let sY;
    let dX;
    let dY;
    const threshold = 100;
    /* [min distance traveled to be considered swipe] */
    const slack = 500;
    /* [max distance allowed at the same time in perpendicular direction] */
    const alT = 500;
    /* [max time allowed to travel that distance] */
    let elT; /* [elapsed time] */
    let stT; /* [start time] */

    function removeScrollAnimation(e) {
      if (e.target.className !== 'animateScroll') {
        return;
      }
      obj.classList.remove('animateScroll');
    }

    function swipeStartHandler(e) {
      const tchs = e.changedTouches[0];
      swdir = 'none';
      sX = tchs.pageX;
      sY = tchs.pageY;
      stT = new Date().getTime();
    }

    function touchMoveHandler(e) {
      e.preventDefault();
    }

    function touchEndHandler(e) {
      const tchs = e.changedTouches[0];
      dX = tchs.pageX - sX;
      dY = tchs.pageY - sY;
      elT = new Date().getTime() - stT;
      if ((elT <= alT) || (Math.abs(dX) >= threshold && Math.abs(dY) <= slack)) {
        if (Math.abs(dY) >= threshold && Math.abs(dX) <= slack) {
          swdir = (dY < 0) ? 'up' : 'down';
        }

        if (obj.id === blockId) {
          if (swdir === 'up') {
            scrollDirection = swdir;
            scrollY(obj);
          } else if (swdir === 'down' && obj.style.transform !== 'translateY(0)') {
            scrollDirection = swdir;
            scrollY(obj);
          }
          e.stopPropagation();
        }
      }
    }

    obj.addEventListener('touchstart', swipeStartHandler, false);
    scrollEvents.push(new ScrollEvent('touchstart', swipeStartHandler));

    obj.addEventListener('touchmove', touchMoveHandler, false);
    scrollEvents.push(new ScrollEvent('touchmove', touchMoveHandler));

    obj.addEventListener('touchend', touchEndHandler, false);
    scrollEvents.push(new ScrollEvent('touchend', touchEndHandler));

    obj.addEventListener('transitionend', removeScrollAnimation, false);
    scrollEvents.push(new ScrollEvent('transitionend', removeScrollAnimation));
  }

  const mainScrollPage = document.querySelector(`#${blockId}`);

  removeEvents(mainScrollPage, scrollEvents);

  mainScrollPage.style.transform = 'translateY(0)';

  mainScrollPage.addEventListener('wheel', scrollY);
  swipe(mainScrollPage);

  scrollEvents.push(new ScrollEvent('wheel', scrollY));
}
