<template>
    <swiper
        class="main-swiper"
        direction="horizontal"
        :centeredSlides=true
        :slides-per-view="1"
        :mousewheel=true
        navigation
        :pagination="{
            clickable: true,
            renderBullet: function (index, className) {
                // eslint-disable-next-line max-len
                return '<span class=' + className + ' main-slide='+index+'>' + (index + 1) + '</span>';
            },
        }"
        :breakpoints="{
            768: {
                direction: 'vertical',
            },
        }"
        :autoplay="{
            delay: 6000
        }
    ">

        <swiper-slide class="main-swiper-slide" v-for="(slide, index) of slides"
                      :key="index">
            <div class="main-swiper-slide__content">
                <div></div>

                <div class="main-swiper-slide__title">{{ $tm(`text.${slide.title}`) }}</div>
            </div>

            <div class="main-swiper-slide__image">
                <img :src="require(`@/assets/images/main/${slide.image}`)" alt=""/>
            </div>

            <div class="main-swiper-slide__animation-block">
                <div class="main-swiper-slide__animations-item">
                    <video width="100%"
                           height="auto"
                           autoplay loop autobuffer muted playsinline>
                        <source :src="require(`@/assets/images/main/animation/${slide.animation}`)"
                                type="video/webm">
                    </video>
                </div>
            </div>

            <div :class="backgroundSmallColor(slide.id)"
                 class="main-swiper-slide__background
                        main-swiper-slide__background_small"></div>

            <div class="main-swiper-slide__background
                        main-swiper-slide__background_large
                        main-swiper-slide__background_blue-middle"></div>
        </swiper-slide>

    </swiper>
</template>

<script>
import SwiperCore, {
  Pagination, Navigation, Autoplay, A11y,
} from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/navigation/navigation.scss';

SwiperCore.use([Pagination, Navigation, Autoplay, A11y]);

// Import Swiper styles
export default {
  name: 'main-swiper',
  mySwiper: document.querySelector('.swiper-container'),

  components: {
    Swiper,
    SwiperSlide,
  },

  props: {
    slides: Array,
  },

  methods: {
    backgroundSmallColor(idx) {
      return {
        'main-swiper-slide__background_purple': idx === 1,
        'main-swiper-slide__background_yellow': idx === 2,
        'main-swiper-slide__background_green': idx === 3,
      };
    },
  },
};
</script>
