<template>

    <div class="right-place-static">

        <div class="right-place-static__content">

            <div class="right-place-static__title">{{ $tm(`text.rightPlaceTitle`) }}</div>

            <div class="right-place-static__items">
                <div class="container">
                    <div class="row">

                        <div class="col-xl-6
                                    right-place-static__item
                                    right-place-static_item_1"
                             @mouseover="mouseOver(1)"
                             v-on:click="toFeedbackForm">
                            {{ $tm('text.point1') }}
                        </div>

                        <div class="col-xl-6
                                    right-place-static__item
                                    right-place-static_item_2"
                             @mouseover="mouseOver(2)"
                             v-on:click="toFeedbackForm">
                            {{ $tm('text.point2') }}
                        </div>

                        <div class="col-6
                                    right-place-static__item
                                    right-place-static_item_3"
                             @mouseover="mouseOver(3)"
                             v-on:click="toFeedbackForm">
                            {{ $tm('text.point3') }}
                        </div>

                        <div class="col-6
                                    right-place-static__item
                                    right-place-static_item_4"
                             @mouseover="mouseOver(4)"
                             v-on:click="toFeedbackForm">
                            {{ $tm('text.point4') }}
                        </div>

                    </div>
                </div>
            </div>

            <div class="right-place-static__image"
                 :class="{'right-place-static_image_hovered': hovered}">
                <img :src="require(`@/assets/images/place/point-${placeImg}.svg`)" alt=""/>
            </div>

            <div class="right-place-static__background
                        right-place-static_background_blue"></div>
        </div>
    </div>

    <swiper
        class="right-place-swiper"
        :centeredSlides=true
        :speed="600"
        :slides-per-view="1"
        :mousewheel=true
        navigation>

        <swiper-slide v-for="(slide, index) of items"
                      :key="index">
            <div class="right-place-static__content">

                <div class="right-place-static__title">
                    {{ $tm('text.rightPlaceTitle') }}
                </div>

                <div class="right-place-static__items">
                    <div class="col-xl-6
                                right-place-static__item"
                         :class="`right-place-static_item_${slide.id}`">
                        {{ $tm(`text.${slide.title}`) }}
                    </div>
                </div>
            </div>

            <div class="right-place-static__image">
                <img :src="require(`@/assets/images/place/${slide.image}`)" alt=""/>
            </div>

            <div class="right-place-static__background
                        right-place-static_background_blue"></div>
        </swiper-slide>

    </swiper>
</template>

<script>
import SwiperCore, {
  Pagination, Navigation, Autoplay, A11y,
} from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/swiper.scss';
/* eslint import/no-extraneous-dependencies: ["error", {"devDependencies": true}] */

import 'swiper/components/pagination/pagination.scss';
/* eslint import/no-extraneous-dependencies: ["error", {"devDependencies": true}] */
import 'swiper/components/navigation/navigation.scss';

SwiperCore.use([Pagination, Navigation, Autoplay, A11y]);

// Import Swiper styles
export default {
  name: 'right-place',
  mySwiper: document.querySelector('.swiper-container'),

  components: {
    Swiper,
    SwiperSlide,
  },

  props: {
    items: Array,
  },

  data() {
    return {
      hovered: false,
      placeImg: 1,
    };
  },

  methods: {

    toFeedbackForm() {
      if (window.innerWidth > 767) {
        document.querySelector('#mainScrollPage').style.transform = 'translateX(-300vw)';
      }
    },

    mouseOver(i) {
      this.hovered = true;
      this.placeImg = i;
      setTimeout(() => {
        this.hovered = false;
      }, 1000);
    },
  },
};
</script>
