<template>
    <form class="easy-find-block__form"
          @submit.prevent="submitForm"
          enctype="multipart/form-data">
        <div class="container-fluid easy-find-block__form-content">
            <div class="row">

                <div class="col">
                    <div class="row easy-find-block__radio-container">

                        <div class="col-6 p-0">

                            <div class="easy-find-block__radio-item">

                                <input class="easy-find-block__radio-input"
                                       type="radio"
                                       id="form-choice1"
                                       name="form-choice"
                                       value="cv"
                                       v-model="picked" checked>

                                <label class="easy-find-block__radio-label"
                                       for="form-choice1">
                                    {{ $tm('text.submitCVRadio') }}
                                </label>

                            </div>
                        </div>

                        <div class="col-6 p-0">

                            <div class="easy-find-block__radio-item">

                                <input class="easy-find-block__radio-input"
                                       type="radio"
                                       id="form-choice2"
                                       name="form-choice"
                                       value="ask"
                                       v-model="picked">

                                <label class="easy-find-block__radio-label"
                                       for="form-choice2">
                                    {{ $tm('text.askRadio') }}
                                </label>

                            </div>
                        </div>

                    </div>
                </div>

                <div class="col-sm-12
                            easy-find-block__form-title">

                    <span v-if="picked === 'cv'"> {{ $tm('text.submitCVTitle') }}</span>
                    <span v-else>{{ $tm('text.askTitle') }}</span>
                </div>

                <div class="col-sm-6 easy-find-block__form-group">

                    <input class="easy-find-block__form-group-input"
                           :class="{ 'is-invalid': attemptSubmit && isEmptyField(name),
                                     'not-empty': !isEmptyField(name) }"
                           type="text"
                           v-model="name"/>
                    <span class="easy-find-block__form-group-bar"></span>

                    <label class="easy-find-block__form-group-label">
                        {{ $tm('text.fieldName') }}
                    </label>

                    <span class="easy-find-block__error-text"
                          v-if="attemptSubmit && isEmptyField(name)">
                        {{ $tm('text.errorRequired') }}
                    </span>

                </div>

                <div class="col-sm-6 easy-find-block__form-group">

                    <input class="easy-find-block__form-group-input"
                           :class="{ 'is-invalid': attemptSubmit &&
                                   (isEmptyField(email) || !isEmailValid),
                                   'not-empty': !isEmptyField(email)}"
                           type="text"
                           v-model="email"/>
                    <span class="easy-find-block__form-group-bar"></span>

                    <label class="easy-find-block__form-group-label">E-mail</label>
                    <span class="easy-find-block__error-text"
                          v-if="attemptSubmit && isEmptyField(email)">
                        {{ $tm('text.errorRequired') }}
                    </span>

                    <span class="easy-find-block__error-text"
                          v-else-if="attemptSubmit && !isEmailValid">
                        {{ $tm('text.errorEmailFormat') }}
                    </span>

                </div>

                <div class="col easy-find-block__form-group">

                    <textarea class="easy-find-block__form-group-textarea"
                              :class="{ 'is-invalid': attemptSubmit && isEmptyField(message),
                                         'not-empty': !isEmptyField(message)}" rows="1"
                              v-model="message"></textarea>

                    <span class="easy-find-block__form-group-bar"></span>

                    <label v-if="picked === 'cv'"
                           class="easy-find-block__form-group-label">
                        {{ $tm('text.fieldMessageCandidate') }}
                    </label>
                    <label v-else
                           class="easy-find-block__form-group-label">
                        {{ $tm('text.fieldMessageClient') }}
                    </label>

                    <span class="easy-find-block__error-text"
                          v-if="attemptSubmit && isEmptyField(message)">
                       {{ $tm('text.errorRequired') }}
                   </span>

                </div>

                <div class="col-auto easy-find-block__form-group d-none d-sm-block"
                     :class="{ 'hide-block': picked !== 'cv',}">
                    <input type="file" id="file1" name="file" class="inputfile"
                           @change="processFile($event)"/>
                    <label class="easy-find-block__form-button
                                              easy-find-block_form-button_transparent"
                           for="file1">
                        <div class="easy-find-block_form-button-body">
                            <img class="easy-find-block_form-button-img"
                                 :src="require(`@/assets/images/find-form/upload.svg`)"
                                 alt=""/>
                            <span v-if="files"> {{ $tm('text.uploadedFile') }}</span>
                            <span v-else>{{ $tm('text.fieldUploadFile') }}</span>
                        </div>
                    </label>
                </div>

                <div class="col-sm-12 easy-find-block__form-group-actions">
                    <div class="row d-flex justify-content-between">

                        <div class="col-sm-auto col-md pl-0">

                            <div class="col">

                                <div class="easy-find-block__form-group-checkbox">
                                    <input type="checkbox"
                                           name="checkThis"
                                           id="checkThis1"
                                           value="checkThis"
                                           v-model="agreeNews">

                                    <label for="checkThis1">
                                        <mark></mark>
                                        <span v-html="$tm('text.getNewsCheckbox')"></span>
                                    </label>
                                </div>

                            </div>

                            <div class="col">

                                <div class="easy-find-block__form-group-checkbox">
                                    <input type="checkbox"
                                           name="checkThis"
                                           id="checkThis2"
                                           value="checkThis"
                                           v-model="agreePolicy">

                                    <label for="checkThis2">
                                        <mark :class="{ 'easy-find-block__error-checkbox ':
                                              attemptSubmit && isEmptyField(agreePolicy)}"></mark>
                                        <span v-html="$tm('text.privacyPolicyCheckbox')"></span>

                                    </label>
                                </div>

                            </div>

                        </div>

                        <div class="col-sm col-md-auto my-auto d-flex justify-content-end">

<!--                            <button class="easy-find-block__form-button g-recaptcha"-->
<!--                                    type="submit"-->
<!--                                    data-sitekey="6LevOdoZAAAAAJMre7hbmerlYVDxe_mVRXX1_AlP"-->
<!--                                    data-callback='onSubmit'>-->
<!--                                <span>{{ $tm('text.buttonSend') }}</span>-->
<!--                            </button>-->

                            <button class="easy-find-block__form-button"
                                    type="submit"
                                    @click="onRecaptchaSubmit">
                                <span>{{ $tm('text.buttonSend') }}</span>
                            </button>

                        </div>

                    </div>
                </div>

            </div>

        </div>

        <footer class="row easy-find-block__form-footer">

            <div class="col-auto
                                easy-find-block__text-contacts
                                easy-find-block_text-contacts_group1">

                <div class="easy-find-block__text-contacts-content"
                    :class="[`easy-find-block_text-contacts-content_${$i18n.locale}`]">
                    <div class="text-contacts__item
                            text-contacts_item_bold">
                        {{ $tm('text.addressCompany') }}
                    </div>

                    <div class="text-contacts__item
                            text-contacts_item_blue">
                        {{ $tm('text.addressCompanyDetail') }}
                    </div>
                </div>

            </div>

            <div class="col-auto
                                easy-find-block__text-contacts
                                easy-find-block_text-contacts_group3">

                <div class="easy-find-block__text-contacts-content"
                    :class="[`easy-find-block_text-contacts-content_${$i18n.locale}`]">
                    <div class="text-contacts__item
                                text-contacts_item_one-line
                                text-contacts_item_bold">
                        {{ $tm('text.emailCompany') }}
                    </div>
                </div>

            </div>

        </footer>

        <div class="submit-form-answer">
            <div class="submit-form-answer__content">
                <div class="submit-form-answer__content-title">
                    {{ $tm('text.submitTitle') }}
                </div>
                <div class="submit-form-answer__content-message">
                    {{ $tm('text.submitMessage') }}
                </div>
            </div>
        </div>
    </form>

</template>

<script>

import { EMAIL_CHECK_REGEX } from '../../../../core/utils/constants';

const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
document.body.style.height = `${h}px`;

export default {
  name: 'find-form',

  created() {
    window.onSubmit = this.onSubmit;
  },

  data() {
    return {
      picked: '',
      name: '',
      phone: '',
      email: '',
      message: '',
      files: '',
      agreeNews: false,
      agreePolicy: false,
      attemptSubmit: false,

      submitAnswerBlock: '',
    };
  },

  computed: {
    isEmailValid() {
      return EMAIL_CHECK_REGEX.test(this.email);
    },
  },

  mounted() {
    this.picked = 'cv';
    this.submitAnswerBlock = document.querySelector('.submit-form-answer');

    function removeSubmitAnswer() {
      document.querySelector('.submit-form-answer').classList.remove('submit-form-answer_show');
    }

    this.submitAnswerBlock.addEventListener('animationend', removeSubmitAnswer);
  },

  methods: {
    isEmptyField(field) {
      return field === '' || field === false;
    },

    validateForm() {
      this.attemptSubmit = true;
      return !this.isEmptyField(this.name)
            && (!this.isEmptyField(this.email))
            && !this.isEmptyField(this.message)
            && !this.isEmptyField(this.agreePolicy);
    },

    processFile(event) {
      // eslint-disable-next-line prefer-destructuring
      this.files = event.target.files[0];
    },

    onRecaptchaSubmit(e) {
      e.preventDefault();
      // eslint-disable-next-line no-undef
      grecaptcha.ready(() => {
        // eslint-disable-next-line no-unused-vars,no-undef
        grecaptcha.execute('6LevOdoZAAAAAJMre7hbmerlYVDxe_mVRXX1_AlP', { action: 'submit' }).then((token) => {
          // Add your logic to submit to your backend server here.
          this.submitForm();
        });
      });
    },

    submitForm() {
      if (this.validateForm()) {
        const formData = new FormData();
        formData.append('name', this.name);
        formData.append('email', this.email);
        formData.append('body', this.message);
        formData.append('pdf', this.files);
        formData.append('get_updates', this.agreeNews);

        const requestOptions = {
          method: 'POST',
          body: formData,
          credentials: 'omit',
        };

        fetch('/api/application/summary/create/', requestOptions)
          .then((response) => response.json())
          .then(() => {
            this.submitAnswerBlock.classList.add('submit-form-answer_show');
            this.cleanForm();
          })
          .catch(
            (error) => console.log(error),
          );
      }
    },

    cleanForm() {
      this.name = '';
      this.phone = '';
      this.email = '';
      this.message = '';
      this.files = '';
      this.agreeNews = false;
      this.agreePolicy = false;
      this.attemptSubmit = false;
    },

  },
};
</script>
