<template>
    <div class="container achievements-block d-flex h-100">
        <div class="row achievements-block_center">
            <div v-for="(item, index) of items"
                 :key="index"
                 class="col-md-4 col-12 achievements-block__item">

                <div class="achievements-block__content">

                    <div class="achievements-block__image
                                d-flex justify-content-center align-self-center"
                         :class="`achievements-block_image_${item.id}`">
                        <img :src="require(`@/assets/images/achieved/${item.image}`)" alt=""/>
                    </div>

                    <div>
                        <div class="achievements-block__content-title">
                            {{ $tm(`text.${item.title}`) }}
                        </div>

                        <div class="achievements-block__content-description">
                            {{ $tm(`text.${item.description}`) }}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'achievements',

  props: {
    items: Array,
  },
};
</script>
